<template>
  <div class="pmz-partners-map">
    <div class="title-3">Карта партнера</div>
    <p>Мы открыты к сотрудничеству. Поэтому хотим сразу перейти к делу. Всю нужную информацию о нашей компании вы можете увидеть в документе</p>
    <a
      download
      :href="$store.state.strapiData.commonData.partnersMapUrl"
      target="_blank"
    >
      <MPZButton label="Открыть"/>
    </a>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.pmz-partners-map{
  background: #F7F7F7;
  border-radius: 30px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  .title-3{
    opacity: .5;
    margin-bottom: 35px;
  }
  p{
    margin-bottom: 30px;
  }
}
</style>